import { ProvaApi } from "../Api/ProvaApi";
import { accordionAtividades } from "../ui/accordions";
import { ConteudoView } from "../ui/views/ConteudoView";
import { ProvasView } from "../ui/views/ProvasView";
import { RecuperacaoView } from "../ui/views/RecuperacaoView";
import { SimuladoView } from "../ui/views/SimuladoView";

export class ProvaController {
    constructor() {
        this._provaApi = new ProvaApi();
        this._provasView = new ProvasView(".provas__wrapper");
        this._simuladoView = new SimuladoView(".provas__wrapper");
        this._conteudoView = new ConteudoView(".provas__wrapper");
        this._recuperacaoView = new RecuperacaoView(".provas__wrapper")
        this._ensinos = document.querySelectorAll(".intro-nav__item");
        this._options = document.querySelectorAll(".chapter-option__select > option");
        this._tipos = document.querySelectorAll(".provas-tabs__item");
        this._tp = this._tipos[0];
        this._idActive = 1;
        this._tipoActive;
        this.init();
    }

    init() { 
        console.log(this._options)
        this._ensinos[0].setAttribute("data-active", "true");
        this._ensinos[0].style.opacity = "0.5";

        this._tipos[0].style.opacity = "0.5";
        this._tipos[0].setAttribute("data-active", "true");

        this._ensinos.forEach(es => es.addEventListener("click", () => {
            this._ensinos.forEach(es => {
                es.removeAttribute("data-active");
                es.style.opacity = "1";
            });
            es.style.opacity = "0.5";
            es.setAttribute("data-active", "true");
            this._idActive = es.getAttribute("data-nivei");
            this.fecthData(this._tp)
        }));
        
        document.querySelector('.chapter-option__select').addEventListener("change", (e) => {
            this._idActive = e.target.value;
            this.fecthData(this._tp)
        })

        this.checkTipoActive();
        this.fecthData(this._tipoActive);
        
        this._tipos.forEach(tp => tp.addEventListener("click", () => {
            this._tipos.forEach(t => {
                t.removeAttribute("data-active");
                t.style.opacity = "1";
            })
            
            tp.style.opacity = "0.5";
            tp.setAttribute("data-active", "true");
            this.fecthData(tp);
            this._tp = tp
        }));


    }

    checkTipoActive() {
        this._tipos.forEach(tp => {
            if(tp.hasAttribute("data-active")) {
                this._tipoActive = tp;
            }
        });
    
    }

    async fecthData(tp) {
        // this._ensinos.forEach(es => {
        //     if(es.hasAttribute("data-active")) {
        //         this._idActive = es.getAttribute("data-nivei");
        //     }
        // });
        console.log(this._idActive)
        console.log(tp.getAttribute("data-name"))
        if(tp.getAttribute("data-name") == "calendario") {
            const calendarioProva = await this._provaApi.getCalendarioProva(this._idActive);
            
            this._provasView.update(calendarioProva);
            this._showButton();
        }

        if(tp.getAttribute("data-name") == "conteudo") {
            const conteudoProva = await this._provaApi.getConteudoProva(this._idActive);

            this._conteudoView.update(conteudoProva);

            document.querySelectorAll(".atividades__button").forEach(btn => {
                
                btn.addEventListener("click", () => {
                    const divToShow = btn.nextElementSibling;
                    divToShow.classList.toggle("atividades__group__open")
                })
            });
            this._showButton();
        }

        if(tp.getAttribute("data-name") == "bimestral") {
            const conteudoBimestral = await this._provaApi.getBimestral(this._idActive)

            this._recuperacaoView.update(conteudoBimestral);
            document.querySelectorAll(".atividades__button").forEach(btn => {
                
                btn.addEventListener("click", () => {
                    const divToShow = btn.nextElementSibling;
                    divToShow.classList.toggle("atividades__group__open")
                })
            });
            this._showButton();
        }

        if(tp.getAttribute("data-name") == "simulado") {
            const simuladoProva = await this._provaApi.getSimuladoProva(this._idActive);

            this._simuladoView.update(simuladoProva);
            this._showButton();
        }

        if(tp.getAttribute("data-name") == "recuperacao") {
            const recuperacao = await this._provaApi.getRecuperacao(this._idActive)

            this._recuperacaoView.update(recuperacao);
            document.querySelectorAll(".atividades__button").forEach(btn => {
                
                btn.addEventListener("click", () => {
                    const divToShow = btn.nextElementSibling;
                    divToShow.classList.toggle("atividades__group__open")
                })
            });
            this._showButton();
        }

    }

    _showButton() {
        const buttons = document.querySelectorAll(".atividades__icon");
        let accordionBody;

        buttons.forEach(bt => {
            bt.addEventListener("click", () => {
                accordionBody = bt.parentNode.nextElementSibling;

                if(accordionBody.style.maxHeight) {
                    accordionBody.style.maxHeight = null;
                    bt.innerHTML = `
                        <span class="material-symbols-outlined">
                        chevron_right
                        </span>
                    `;
                    bt.style.opacity = "1";
                } else {
                    accordionBody.style.maxHeight = (accordionBody.scrollHeight + 500) + "px";
                    bt.innerHTML = `
                        <span class="material-symbols-outlined">
                        expand_more
                        </span>
                    `;
                    bt.style.opacity = "0.5";
                }
            })
        });
    }
}