import { MaterialApi } from "../Api/MaterialApi";
import { accordionAtividades } from "../ui/accordions";
import { MaterialView } from "../ui/views/MaterialView";

export class MaterialController {
    constructor() {
        this._materialApi = new MaterialApi();
        this._materialView = new MaterialView(".wrapper__atividades");
        this._listTabs = document.querySelectorAll(".atividades__tabs");
        this._listTabsMobile = document.querySelectorAll(".chapter-option__select > option");
        this._list = [];
        this.init();
    }

    async init() {
        const material = await this._materialApi.getMateriais();
        this._materialView.update(material);
        this._attachEventListeners();
        accordionAtividades();

        this._listTabs[0].setAttribute("data-active", true);
        this._listTabsMobile[0].setAttribute("data-active", true);

        this._listTabs[0].style.opacity = "0.5"
        this._listTabsMobile[0].style.opacity = "0.5";

    }

    async _updateList(itemId) {
        this._list = await this._materialApi.getMateriais(itemId);
        this._materialView.update(this._list);
        accordionAtividades();
    }

    _attachEventListeners() {
        this._listTabs.forEach((item, itemIndex) => item.addEventListener("click", () => {
            this._listTabs.forEach(lt => lt.style.opacity = "1");
            this._listTabsMobile.forEach(lt => lt.style.opacity = "1");

            this._listTabs[itemIndex].style.opacity = "0.5"
            this._listTabsMobile[itemIndex].style.opacity = "0.5";

            this._updateList(item.getAttribute("data-id"))
        }));

        document.querySelector('.chapter-option__select').addEventListener("change", (e) => {
            const optionValue = e.target.value
            let id;
            switch (optionValue) {
                case 'Educação Infantil':
                    id = 1;
                case 'Ensino Fundamental I':
                    id = 2;
                    break;
                case 'Ensino Fundamental II':
                    id = 3;
                    break;
                case 'Ensino Médio':
                    id = 4;
                    break;
                case 'Esporte':
                    id = 5;
                    break;
                case 'Laboratório':
                    id = 6;
                    break;
                default:
                    break;
            }
            
            this._updateList(id)

            console.log(id)
        })

    }
}