import { View } from "./View";

export class ProvasView extends View {
    template(model) {
        return model.map(item => {
            return `
                <div class="atividades">
                    <div class="atividades__header">
                        <div>
                            <div class="atividades__title">
                                <b>
                                    Classe
                                </b>
                            </div>
                            <div class="atividades__subtitle">${item.descricao}</div>
                        </div>
                        <button class="atividades__icon">
                            <span class="material-symbols-outlined">
                                chevron_right
                            </span>
                        </button>
                    </div>

                    <div class="atividades__options">
                        <div class="atividades__classes">
                            <button class="atividades__button"><a href="Arquivos/prova/${item.nomeArquivoCompleto}" target="_blank">Ver calendário</a></button>
                        </div>
                    </div>
                </div>
            `;
        }).join("");
    }
}