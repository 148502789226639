import { MultimidiaApi } from "../Api/MultimidiaApi";
import { MultimidiaHomeView } from "../ui/views/MultimidiaHomeView";

export class MultimidiaHomeController {
    constructor() {
        this._multimidiaApi = new MultimidiaApi();
        this._multimidiaHomeView = new MultimidiaHomeView("#header-multimidia");
        this.init();
    }

    async init() {
        const multimidias = await this._multimidiaApi.getLastestAlbums();
        
        this._multimidiaHomeView.update(multimidias);
    } 
}