import { HttpService } from "../util/HttpService";

export class AtividadeApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._ATIVIDADE_ID = window.location.search.split("=")[1];
        this._httpService = new HttpService();
    }

    get(id, optionsId) {
        // optionsId = optionsId || "1,2,3,4,5,6,7,8,9";
        return this._httpService.get(`${this._URL_BASE}/atividades/classes/${id}/tipos-atividades?CodTipo=${optionsId}`);
    }
}