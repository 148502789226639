export class Pagination {
    constructor(container, list, pageNumber, numberPerPage, fn) {
        this._container = container;
        this._list = list;
        this._pageNumber = pageNumber;
        this._numberPerPage = numberPerPage;
        this._pageCount = 0;
        this._fn = fn;
    }

    addPagination() {
        this._fn(this._filterList(this._list));
        this._pageCount = Math.ceil(this._list.length / this._numberPerPage);

        if(this._pageCount !== 0) {
            document.querySelector(`${this._container}`).innerHTML = `
                <button class="multimidia__indexes__button">
                    <span class="material-symbols-outlined">
                    chevron_left
                    </span>
                </button>
                <span>${this._pageNumber} de ${this._pageCount}</span>
                <button class="multimidia__indexes__button">
                    <span class="material-symbols-outlined">
                    chevron_right
                    </span>
                </button>
            `;
        }
        
        const buttons = document.querySelectorAll(".multimidia__indexes__button");
        buttons[0].addEventListener("click", this._handlePrevButton.bind(this));
        buttons[1].addEventListener("click", this._handleNextButton.bind(this));
    }

    _filterList(list) {
        const filteredList = list.filter((l, index) => index >= ((this._pageNumber - 1) * this._numberPerPage)  && index < (this._pageNumber * this._numberPerPage));
        return filteredList;
    }

    _handleNextButton() {
        if(this._pageNumber >= this._pageCount) {
            this._pageNumber = this._pageCount;
        } else {
            this._pageNumber = this._pageNumber + 1;
        }
        this._updatePageIndex();
    }

    _handlePrevButton() {
        if(this._pageNumber <= 1) {
            this._pageNumber = 1;
        } else {
            this._pageNumber = this._pageNumber - 1;
        }
        this._updatePageIndex();
    }

    _updatePageIndex() {
        document.querySelector(`${this._container} > span`).innerHTML = `${this._pageNumber} de ${this._pageCount}`;
        
        this._fn(this._filterList(this._list));
    }
}