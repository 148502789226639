import { AtividadeApi } from "../Api/AtividadeApi";
import { AtividadeView } from "../ui/views/AtividadeView";

export class AtividadeController {
    constructor(id) {
        this._id = id;
        this._inputOptions = Array.from(document.querySelectorAll(".atividade__input"));
        this._dateInput = document.querySelector(".atividade__inputdate");
        this._dateInput.valueAsDate = new Date();
        this._atividadeApi = new AtividadeApi();
        this._atividadeView = new AtividadeView(".atividade__content");

        this._init();
    }

    _init() {
        this._fillAllOptions();
        this._attachEventListeners();
        this._filterAtividade();
    }

    async _filterAtividade() {
        try {
            const atividades = await this._atividadeApi.get(this._id, this._listOfIds());
            const filteredAtividades = atividades.filter(item => this._isValidDate(item.dataInicio, item.dataTermino));
                
            this._atividadeView.update(filteredAtividades);

            const atividadeBoxs = document.querySelectorAll(".atividade__description");

            atividadeBoxs.forEach((item, index) => {
                item.innerHTML = document.querySelectorAll(".atividade__description")[index].innerText;
            });
        } catch (error) {
            console.error("Error: ", error);
        }
    }
    
    _listOfIds() {
        const optionsList = this._inputOptions.filter(option => (option.checked == true && option.getAttribute("data-id") != "-1"));
        return optionsList.map(option => option.getAttribute("data-id")).join() || null;
    }

    _fillAllOptions() {
        this._inputOptions.forEach(item => item.checked = true);
    }

    _hasTodos() {
        if(this._inputOptions[0].checked == true) {
            this._fillAllOptions();
        } else {
            this._inputOptions.forEach(option => option.checked = false);
        }
        this._filterAtividade();
    }

    _checkEspecific() {
        if(this._inputOptions.filter(item => item.checked == true).slice(1).length < this._inputOptions.slice(1).length) {
            this._inputOptions[0].checked = false;
        } else {
            this._fillAllOptions();
        }
        this._filterAtividade();
         
    }

    _isValidDate(initialDate, finalDate) {
        const currentDate = new Date(this._dateInput.value).getTime();
        const initialTransformDate = new Date(initialDate.slice(0, 10)).getTime();
        const finalTransformDate = new Date(finalDate.slice(0, 10)).getTime();
        return currentDate >= initialTransformDate && currentDate <= finalTransformDate;
    }

    _attachEventListeners() {
        this._inputOptions[0].addEventListener("click", this._hasTodos.bind(this));
        this._inputOptions.forEach(option => {
            option.addEventListener("click", this._checkEspecific.bind(this))
            option.addEventListener("click", this._filterAtividade.bind(this))
        });
        this._dateInput.addEventListener("change", this._filterAtividade.bind(this));
    }
}