import { MultimidiaApi } from "../Api/MultimidiaApi";
import { AlbumsView } from "../ui/views/AlbumsView";
import { VideosView } from "../ui/views/VideosView";
import { HttpService } from "../util/HttpService";
import { Pagination } from "../util/Pagination";

export class MultimidiaController {
    constructor(pageNumber = 1, numberPerPage = 3) {
        this._pageNumber = pageNumber;
        this._numberPerPage = numberPerPage;
        this._pageCount = 0;
        this._multimidiaApi = new MultimidiaApi();
        this._albumsView = new AlbumsView("#multimidia-album");
        this._videosView = new VideosView("#multimidia-video");
        this._albumList = [];
        this._videosList = [];
        this._inputOptions = Array.from(document.querySelectorAll(".atividade__input"));
        this._init();
    }

    async _init() {
        this._fillAllOptions();
        this.initAlbums();
        this._attachEventListeners();
    }

    async initAlbums() {
        document.querySelector(".multimidia-filtro").style.display = "flex";

        this._albumList = await this._multimidiaApi.getAllAlbums(this._listOfIds());
      
        this._pagination = new Pagination(
            ".multimidia__indexes", 
            this._albumList, 
            this._pageNumber, 
            this._numberPerPage, 
            (e) => this._albumsView.update(e)
        );
        this._pagination.addPagination();
    }

    async initVideos() {
        document.querySelector(".multimidia-filtro").style.display = "none";
        this._videosList = await this._multimidiaApi.getAllVideos();
        
        this._pagination = new Pagination(
            ".multimidia__indexes", 
            this._videosList, 
            this._pageNumber, 
            this._numberPerPage, 
            (e) => this._videosView.update(e)
        );
        this._pagination.addPagination();
    }

    _checkEspecific() {
        if(this._inputOptions.filter(item => item.checked == true).slice(1).length < this._inputOptions.slice(1).length) {
            this._inputOptions[0].checked = false;
        } else {
            this._fillAllOptions();
        }
        this.initAlbums();
         
    }

    _hasTodos() {
        if(this._inputOptions[0].checked == true) {
            this._fillAllOptions();
        } else {
            this._inputOptions.forEach(option => option.checked = false);
        }

        this.initAlbums();
    }

    _fillAllOptions() {
        this._inputOptions.forEach(item => item.checked = true);
    }

    _listOfIds() {
        const optionsList = this._inputOptions.filter(option => (option.checked == true && option.getAttribute("data-id") != "-1"));
        return optionsList.map(option => option.getAttribute("data-id")).join() || null;
    }

    _attachEventListeners() {
        this._inputOptions[0].addEventListener("click", this._hasTodos.bind(this));
        this._inputOptions.forEach(option => {
            option.addEventListener("click", this._checkEspecific.bind(this))
            option.addEventListener("click", this.initAlbums.bind(this))
        });
    }
}