export class RouterController {
    constructor() {
        this._currentURL = new URL(window.location);
        this._pathName = this._currentURL.pathname.toLocaleLowerCase();
        this._urlParams = new URLSearchParams(this._currentURL.search);
        this._urlID = this._urlParams.get("id");
        this._urlCategory = this._urlParams.get("categoria");
    }

    get(urls, fn) {
        if(Array.isArray(urls)) {
            for(const url of urls) {
                this._handleRoute(url, fn);
            }
        } else {
            this._handleRoute(urls, fn);
        }
    }
    
    _handleRoute(url, fn) {
        if(url == "/*") return fn(null);
        
        const lowercaseUrl = url.toLowerCase();
        
        if(lowercaseUrl.includes(":") && this._hasParameterId(url)) fn(null);
        
        if(lowercaseUrl === this._pathName && (this._urlID !== undefined && this._urlID !== null)) return fn(this._urlID);

        if(lowercaseUrl === this._pathName && this._urlCategory !== null) return fn(this._urlCategory)
        
        if(lowercaseUrl === this._pathName) return fn(null);
    }

    _hasParameterId(url) {
        const newURL = url.split("/").slice(0, -1).join("/");
        const newPathName = this._pathName.split("/").slice(0, -1).join("/");
        
        return newURL == newPathName
    }
}