import { View } from "./View";

export class MultimidiaHomeView extends View {
    template(model) {
        return model.map(item => {
            return `
                <a href="https://${window.location.host}/multimidia/album/${item.codAlbum}">
                    <div class="header-menu__item">
                        <img src="/Imagens/galeriafotos/${item.codAlbum}/tb-${item.nomeArquivoCapaAlbum}" alt="${item.titulo}" class="header-menu__image">
                        <div class="header-menu__title">${item.titulo}</div>
                    </div>
                </a>
            `;
        }).join("");
    }
}