import { View } from "./View";

export class SimuladoView extends View {
    template(model) {
        return model.map(item => {
            return `
                <div class="atividades">
                    <div class="atividades__header">
                        <div>
                            <div class="atividades__title">
                                <b>
                                    Classe
                                </b>
                            </div>
                            <div class="atividades__subtitle">${item.descricaoAno} - ${item.bimestre} bimestre</div>
                        </div>
                        <button class="atividades__icon">
                            <span class="material-symbols-outlined">
                                chevron_right
                            </span>
                        </button>
                    </div>

                    <div class="atividades__options">
                        <div class="atividades__classes">
                            <a href="/Arquivos/simulado/${item.nomeArquivoCompleto}" target="_blank"><button class="atividades__button">Ver simulado</button></a>
                        </div>
                    </div>
                </div>
            `;
        }).join("");
    }
}