import menuMobile from "./ui/menuMobile";
import {accordionAtividades, accordionSecretaria, accordionComunicados} from "./ui/accordions";
import { tabAtividades, tabMultimidia, tabProvas } from "./ui/tabs";
import scrollAside from "./ui/scrollAside";
import { acessoModal, multimidiaModal, pagamentosModal, videoHomeModal } from "./ui/modals";
import { AtividadeController } from "./controllers/AtividadeController";
import { ComunicadoHomeController } from "./controllers/ComunicadoHomeController";
import { RouterController } from "./controllers/RouterController";
import { SantaCarousel } from "./lib/SantaCarousel";
import { MultimidiaController } from "./controllers/MultimidiaController";
import { ComunicadosController } from "./controllers/ComunicadosController";
import { MultimidiaHomeController } from "./controllers/MultimidiaHomeController";
import { NoticiasController } from "./controllers/NoticiasController";
import { ProvaController } from "./controllers/ProvaController";
import { MaterialController } from "./controllers/MaterialController";
import { EnviarEmailController } from "./controllers/EnviarEmailController";

const route = new RouterController();

route.get("/*", () => {
    const multimidiaHomeController = new MultimidiaHomeController();
    // scrollAside();
    accordionAtividades();   
    menuMobile();
    
    multimidiaModal();

    try {
        const menunav = document.querySelector(".intro-nav");
        const menusize = menunav.offsetWidth;
        menunav.style.left = `calc(50% - ${menusize / 2}px)`;
    } catch (error) {
        console.log("Menu está indefinido");
    }
});

route.get(["/", "/home"], () => {
    // const enviarEmailController = new EnviarEmailController();
    // document.querySelector("#EnviarFaleConosco").addEventListener("click", enviarEmailController.send.bind(enviarEmailController))

    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const homeCarousel = new SantaCarousel(".home-carousel__wrapper", {
        cardsPerView: 1,
        dots: ".dots",
        cards: ".home-cards",
        dotColor: "#fff",
        arrows: {
            prev: ".home-carousel__prev",
            next: ".home-carousel__next"
        }
    });
    const talentsCarousel = new SantaCarousel(".talents-carousel__wrapper", {
        cardsPerView: 1,
        dots: ".talents-dots",
        isFull: false,
        cards: ".talents-cards",
        dotColor: "#444444",
        arrows: {
            prev: ".talents-carousel__prev",
            next: ".talents-carousel__next"
        }
    });

    document.querySelector(".news-latest__wrapper").classList.remove('not-home')
    // homeCarousel.startAutoPlay();
    videoHomeModal();
    document.querySelector(".header").classList.add("header__home");
    document.querySelector(".wp-container > span").addEventListener("click", () => {
        document.querySelector(".wp-container").style.display = "none";
    });

    function resizeAsideMenu() {
        const asideMenu = document.querySelector(".aside-menu")
        const asideSize = document.querySelector(".aside-menu").offsetWidth
        const asidePadding = asideSize / 2
        if(window.innerWidth >= 1100 && window.innerWidth < 1540) {
            asideMenu.style.left = `calc(50% - ${asidePadding}px)`
        } else {
            asideMenu.removeAttribute('style');
        }
    }

    window.onresize = resizeAsideMenu

    // resizeAsideMenu()
});

route.get("/acesso", () => {
    acessoModal();
});

route.get("/atividade", (id) => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const atividadeController = new AtividadeController(id);
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/atividades", () => {
    tabAtividades();

    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/horarios", () => {
    tabAtividades();

    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get(["/noticia", "/noticias"], (id) => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[2].classList.add("header-menu__link--isActive");
});

route.get("/comunicados", async () => {
    // const comunicadosController = new ComunicadosController();
    accordionComunicados();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/comunicado", (id) => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/acesso/lembrarsenha", (id) => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/equipe", () => {
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const equipeCarousel = new SantaCarousel(".carousel-wrapper", {
        cardsPerView: 3,
        center: true,
        arrows: {
            prev: ".carousel-prev",
            next: ".carousel-next"
        }
    });
    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[0].classList.add("header-menu__link--isActive");
});

route.get("/inscreva-se", () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/multimidia", () => {
    tabMultimidia();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const multimidiaController = new MultimidiaController(1, 8);
    
    document.querySelector("#multimidia-option-albums")
        .addEventListener("click", multimidiaController.initAlbums.bind(multimidiaController));
    document.querySelector("#multimidia-option-videos")
        .addEventListener("click", multimidiaController.initVideos.bind(multimidiaController));

    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[3].classList.add("header-menu__link--isActive");
});

route.get("/multimidia/album/:id", () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[3].classList.add("header-menu__link--isActive");
});

route.get("/matriculas", () => {
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/material", () => {
    // tabProvas();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const materialController = new MaterialController();
    materialController.init();
});

route.get("/provas", () => {
    // tabProvas();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();

    const provasController = new ProvaController();
});

route.get("/secretaria", () => {
    accordionSecretaria();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[1].classList.add("header-menu__link--isActive");
});

route.get("/historia", () => {
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    const menuitems = document.querySelectorAll(".header-menu__link");
    menuitems[0].classList.add("header-menu__link--isActive");
});

route.get("/financeiro/pagamentos", () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
    pagamentosModal();
});

route.get([
    "/ensino/infantil",
    "/ensino/fundamental-I", 
    "/ensino/fundamental-II", 
    "/ensino/medio"], () => {
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get([
    "/atividades",
    "/equipe",
    "/historia",
    "/multimidia",
    "/comunicados",
    "/secretaria",
    "/ensino/infantil",
    "/ensino/fundamental-I",
    "/ensino/fundamental-II",
    "/ensino/medio",
    "/horarios",
    "/provas",
    "/matriculas",
    "/material",
    "/noticias"],
    () => {
    const comunicadoHomeController = new ComunicadoHomeController();
});

route.get("/Academico/Index", () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get(["/Contrato/ComplementarDados", "/Contrato/ExibirContrato"], () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});

route.get("/Contrato/RematriculaJaFinalizada", () => {
    const comunicadoHomeController = new ComunicadoHomeController();
    const noticiasController = new NoticiasController();
    noticiasController.getLatestNews();
});