export class SantaCarousel {
    constructor(container, options) {
        this._options = options;
        this._isFull = options.isFull;
        this._cardsPerView = options.cardsPerView;
        this._carousel = document.querySelector(`${container} > div`);
        this._cards = document.querySelectorAll(options.cards)
        this._prevButton = document.querySelector(options.arrows.prev);
        this._nextButton = document.querySelector(options.arrows.next);
        this._dotContainer = document.querySelector(options.dots);
        this._currentIndex = 0;
        this._cardWith = 1;
        this.init();
    }

    startAutoPlay(interval = 5000) {
        this._autoPlayInterval = setInterval(() => {
            this._currentIndex = (this._currentIndex + 1) % this._cards.length;
            this._updateCarouselPosition();
            this._checkOptions();
        }, interval);
    }

    stopAutoPlay() {
        clearInterval(this._autoPlayInterval);
    }
    
    init() {
        try {
            if(this._cards.length < 2) {
                this._prevButton.style.display = "none"
                this._nextButton.style.display = "none"
            }
            this._updateCardWith();
            this._checkOptions();
            
            
            this._prevButton.addEventListener("click", () => {
                this._currentIndex = (this._currentIndex - 1 + this._cards.length) % this._cards.length;
                this._updateCarouselPosition();
                this._checkOptions();
                this.stopAutoPlay();
            });
            
            this._nextButton.addEventListener("click", () => {
                this._currentIndex = (this._currentIndex + 1) % this._cards.length;
                this._updateCarouselPosition();
                this._checkOptions();
                this.stopAutoPlay();
            });
            
            this.startAutoPlay();

            if(this._isFull == false) {
                this._cards.forEach(item => {
                    item.style.visibility = "hidden";
                })
                this._cards[this._currentIndex].style.visibility = "visible";
            }

            window.addEventListener("resize", () => {
                this._updateCardWith();
                this._updateCarouselPosition();
            })
        } catch (err) {
            console.log(err.message)
        }
    }

    _checkOptions() {
        if(this._options.center) {
            this._centerCard();
        }
        if(this._options.dots) {
            if(this._cards.length > 1) {
                this._createDots();
            }
            this._updateDotsPosition();
        }
    }

    _createDots() {
        let liColletion = "";

        for(let i = 0; i < this._cards.length; i++) {
            liColletion += "<li></li>"
        }

        this._dotContainer.innerHTML = liColletion;
        const dots = document.querySelectorAll(`${this._options.dots} > li`);
        dots.forEach((dot, index) => dot.addEventListener("click", () => {
            this._currentIndex = index;
            this._updateCarouselPosition();
            this._updateDotsPosition();
        }));
    }

    _updateCardWith() {
        const containerWidth = this._carousel.offsetWidth;
        
        this._cardWith = containerWidth / this._cardsPerView;
        
        this._cards.forEach(card => {
            card.style.width = `${this._cardWith}px`;
            card.children[1].style.width = `${this._cardWith}px`;
        });
    }

    _updateCarouselPosition() {
        this._carousel.style.transform = `translateX(-${this._currentIndex * (this._cardWith + 16)}px)`;
        if(this._isFull == false) {
            this._cards.forEach(item => {
                item.style.visibility = "hidden";
            })
            this._cards[this._currentIndex].style.visibility = "visible";
        }
    }

    _updateDotsPosition() {
        const dots = document.querySelectorAll(`${this._options.dots} li`);
        dots.forEach(dot => dot.style.backgroundColor = "transparent");
        dots[this._currentIndex].style.backgroundColor = `${this._options.dotColor}`;
    }

    _centerCard() {
        const currentCard = document.querySelector(".card-current");
        if(currentCard) {
            currentCard.classList.remove("card-current")
        }

        this._cards[this._currentIndex + 1].classList.add("card-current")
    }
}