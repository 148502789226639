import { HttpService } from "../util/HttpService";

export class MultimidiaApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._httpService = new HttpService();
    }
    
    async getLastestAlbums() {
        const niveis = await this._httpService.get(`${this._URL_BASE}/niveis-ensino`);
        const niveisToString = niveis.map(nivel => nivel.codNivelEnsino);
        const albums = await this._httpService.get(`${this._URL_BASE}/Galerias?nivelensino=${niveisToString}`);
        return await albums.filter((album, index) => index == 0 || index <= 1)
    }

    getAllAlbums(listOfId) {
        return this._httpService.get(`${this._URL_BASE}/Galerias?nivelensino=${listOfId}`);
    }

    getAllVideos() {
        return this._httpService.get(`${this._URL_BASE}/Videos`);
    }
}