import { HttpService } from "../util/HttpService";

export class MaterialApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._httpService = new HttpService();
    }
    
    async getMateriais(itemId = 1) {
        const result = await this._httpService.get(`${this._URL_BASE}/lista-materiais?nivelensino=${itemId}`);
        return result;
    }
}