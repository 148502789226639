import { View } from "./View";

export class VideosView extends View {
    template(model) {
        return model.map(item => {
            return `
            <div class="multimidia__card">
                <iframe width="400" height="215" src="https://www.youtube.com/embed/71ShQYxkcoU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="multimidia__body">
                    <h2 class="multimidia__title"><a href="#">${item.titulo}</a></h2>
                </div>
            </div>
            `;
        }).join("");
    }
}