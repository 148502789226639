import { DateConverter } from "../converters/DateConverter";
import { View } from "./View";

export class AtividadeView extends View {
    template(model) {
        if(model.length === 0) return "Nenhum filtro correspondente"
        return model.map(item => {            
            const hasPDF = item.nomeArquivo ? `<a href="./Arquivos/atividade/${item.nomeArquivo}" target="_blank">Baixar arquivo</a>` : ""

            return `
            <div class="atividade__box">
                <h3 class="atividade__title">${item.nome}</h3>
                <p class="atividade__date">(Postado em: ${DateConverter.toString(new Date(item.dataInicio))})</p>
                <div class="atividade__description">
                    ${item.descricao ? item.descricao : ""}

                    <br />
                </div>
                ${hasPDF}
            </div>
            `;
        }).join("");
    }
}