export const tabProvas = () => {
    let tabsButton;
    let bodyContent;

    try {
        tabsButton = document.querySelectorAll(".provas-tabs__item");
        bodyContent = document.querySelectorAll(".provas__wrapper");

        bodyContent.forEach(bc => bc.style.display = "none");
        bodyContent[0].style.display = "flex";
        tabsButton[0].style.opacity = "0.5";

        tabsButton.forEach((button, index) => {
            button.addEventListener("click", () => {
                bodyContent.forEach(bc => bc.style.display = "none");
                tabsButton.forEach(tb => tb.style.opacity = "1");

                bodyContent[index].style.display = "flex";
                tabsButton[index].style.opacity = "0.5";
            });
        });
    } catch (error) {
        console.log(error.message);
    }
}

export const tabMultimidia = () => {
    try {
        const button = document.querySelectorAll(".multimidia-options__button");
        const albunsDiv = document.querySelector("#multimidia-album");
        const videosDiv = document.querySelector("#multimidia-video");

        button.forEach((btn, index) => btn.addEventListener("click", () => {
            if(index == 0) {
                albunsDiv.style.display = "grid";
                videosDiv.style.display = "none";
            } else {
                videosDiv.style.display = "grid";
                albunsDiv.style.display = "none";
            }
        }))
    } catch (error) {
        console.log(error.message);
    }
}

export const tabAtividades = () => {
    let tabsButton;
    let bodyContent;
    let select;
    let selectIndex;

    try {
        tabsButton = document.querySelectorAll(".atividades__tabs");
        bodyContent = document.querySelectorAll(".wrapper__atividades");
        select = document.querySelector(".chapter-option__select");

        select.addEventListener("change", () => {
            selectIndex = select.selectedIndex;
            bodyContent.forEach(bc => bc.style.display = "none");
            bodyContent[selectIndex].style.display = "flex";
        })

        bodyContent.forEach(bc => bc.style.display = "none");
        bodyContent[0].style.display = "flex";
        tabsButton[0].style.opacity = "0.5";

        tabsButton.forEach((button, index) => {
            button.addEventListener("click", () => {
                bodyContent.forEach(bc => bc.style.display = "none");
                tabsButton.forEach(tb => tb.style.opacity = "1");

                bodyContent[index].style.display = "flex";
                tabsButton[index].style.opacity = "0.5";
            });
        });
    } catch (error) {
        console.log(error.message);
    }
}