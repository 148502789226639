import { HttpService } from "../util/HttpService";

export class NoticiasApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._httpService = new HttpService();
    }

    getAllNoticias() {
        return this._httpService.get(`${this._URL_BASE}/noticias?nivelensino=-1`);
    }
    
    getByCategory(categoryId) {
        return this._httpService.get(`${this._URL_BASE}/noticias?nivelensino=${categoryId}`);
    }

    getLatestNews() {
        return this._httpService.get(`${this._URL_BASE}/noticias/recentes`);
    }

    
}