const menuMobile = () => {
    let buttonHamburguer;
    let menu;
    let menuTop;
    let menuTopSub;
    
    try {
        buttonHamburguer = document.querySelector(".hamburguer-icon");
        menu = document.querySelector(".menu-mobile");
        menuTop = document.querySelector(".menu-mobile__button-isLogged");
        menuTopSub = document.querySelector(".menu-mobile__logged");
        
        buttonHamburguer.addEventListener("click", () => {
            menu.classList.toggle("menu-mobile--isOpen");
        });

        menuTop.addEventListener("click", () => {
            menuTopSub.classList.toggle("menu-mobile__logged--isActive")
        });
    } catch (error) {
        console.log(error.message);
    }
}

export default menuMobile;