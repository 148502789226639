import { HttpService } from "../util/HttpService";

export class ProvaApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._httpService = new HttpService();
    }

    async getCalendarioProva(id) {
        return this._httpService.get(`${this._URL_BASE}/calendario-provas?nivelensino=${id}`);
    }

    async getSimuladoProva(id) {
        return this._httpService.get(`${this._URL_BASE}/simulados?nivelensino=${id}`);

    }

    async getConteudoProva(id) {
        return this._httpService.get(`${this._URL_BASE}/conteudo-provas?nivelensino=${id}`);

    }

    async getBimestral(id) {
        return this._httpService.get(`${this._URL_BASE}/conteudo-provasV2?nivelensino=${id}&tipo=1`)
    }

    async getRecuperacao(id) {
        return this._httpService.get(`${this._URL_BASE}/conteudo-provasV2?nivelensino=${id}&tipo=3`)
    }
}