import { View } from "./View";

export class ComunicadoHomeView extends View {
    template(model) {
        if(model.length === 0) return "Nenhum registro"
        
        const comunicados =  model.map(item => {
            return `
                <div class="news-latest__card">
                    <a href="/comunicado?id=${item.codComunicado}" class="news-latest__link">
                    ${item.titulo}
                    </a>
                </div>
            `;
        }).join("");

        return `
            ${comunicados}
                <button class="news-latest__button">
                <a href="/comunicados">
                    Ver mais comunicados
                    <span class="material-symbols-outlined">
                    arrow_forward
                    </span>
                </a>
            </button>
        `
    }
}