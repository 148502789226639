import { View } from "./View";

export class LatestNews extends View {
    template(model) {
        return model.map(item => {
            return `
                <div class="news-card__card">
                    <div class="news-card__image">
                        <img src="/Arquivos/fotonoticia/${item.nomeArquivo}" alt="${item.titulo}" />
                        ${item.tags.length != 0 ?  
                            `<div class="news-card__tip">
                                ${item.tags[0].tag}
                            </div>`
                            
                            : ""}
                    </div>
                ${item.slug ?
                    `<h3 class="news-card__title"><a href="/noticia/${item.slug}">${item.titulo}</a></h3>`
                        : 
                        `<h3 class="news-card__title"><a href="/noticia?id=${item.codNoticia}">${item.titulo}</a></h3>`
                }
                <p class="news-card__paragraph">${item.descricao}</p>
                ${item.slug ?
                    `<a href="/noticia/${item.slug}" class="news-card__link">Continue lendo</a>`
                    :
                    `<a href="/noticia?id=${item.codNoticia}" class="news-card__link">Continue lendo</a>`
                }
                </div>
            `;
        }).join("");
    }
}