export const multimidiaModal = () => {
    let slideIndex;
    try {
        const images = document.querySelectorAll(".album__image");
        const dotsContainer = document.querySelector(".modal-multimidia__bullets");
        let dot = "";
        const modal = document.querySelector(".modal");
        
        images.forEach(img => {
            dot += '<button class="modal-multimidia__circle"></button>';
        });

        dotsContainer.innerHTML = `${dot}`;
        
        images.forEach((img, index) => img.addEventListener("click", () => {
            const closeButton = document.querySelector(".modal-multimidia__button");
            const imagesModal = document.querySelectorAll(".modal-multimidia__images");
            
            modal.style.display = "flex";
            imagesModal.forEach(image => image.style.display = "none");
            imagesModal[index].style.display = "block";
            
            const dotsCircle = document.querySelectorAll(".modal-multimidia__circle");
            
            dotsCircle.forEach(dot => dot.classList.remove("modal-multimidia__circle--active"));
            dotsCircle[index].classList.add("modal-multimidia__circle--active");

            closeButton.addEventListener("click", () => modal.style.display = "none");

            slideIndex = index;

            const arrows = document.querySelectorAll(".modal-multimidia__arrow");
            arrows[0].addEventListener("click", () => plusSlide(-1));
            arrows[1].addEventListener("click", () => plusSlide(1));
        }));
    } catch (error) {
        console.log(error.message);
    }

    function plusSlide(n) {
        showSlides(slideIndex += n);
    }

    function showSlides(n) {
        const slides = document.querySelectorAll(".modal-multimidia__images");
        const dotsCircle = document.querySelectorAll(".modal-multimidia__circle");
        
        if(n > (slides.length - 1)) { slideIndex = 0}
        if(n < 0) { slideIndex = (slides.length - 1) }

        slides.forEach(slide => slide.style.display = "none");
        slides[slideIndex].style.display = "block";

        dotsCircle.forEach(dot => dot.classList.remove("modal-multimidia__circle--active"));
        dotsCircle[slideIndex].classList.add("modal-multimidia__circle--active");  
    }
}

export const acessoModal = () => {
    try {
        const button = document.querySelector('.form-change__button[type="button"]');
        const modal = document.querySelector(".modal");
        const closeButtons = [
            document.querySelector('.modal-acesso__button'),
            document.querySelector('.modal-acesso__header > span')
        ]
    
        button.addEventListener("click", () => {
            modal.style.display = "flex"
        });
    
    
        closeButtons.forEach(button => button.addEventListener('click', closeModal));
    
        function closeModal() {
            modal.style.display = "none"
        }
    } catch (e) {
        console.log(e.message)
    }
}

export const pagamentosModal = () => {
    try {
        const closeButton = document.querySelector(".payments-alert__header > span");
        const card = document.querySelector(".payments-alert");
    
        closeButton.addEventListener("click", () => {
            card.style.display = "none";
        });
    } catch (e) {
        console.log(e.message)
    }
}

export const videoHomeModal = () => {
    try {
        const modal = document.querySelector(".modal");
        const openButton = document.querySelector(".experience__button");
        const closeButton = document.querySelector(".modal-video__header > span");

        openButton.addEventListener("click", () => {
            modal.style.display = "flex";
            isOpen = true;
        });

        closeButton.addEventListener("click", () => {
            modal.style.display = "none";
        });

        window.addEventListener("keydown", (e) => {
            if(e.keyCode == 27) {
                modal.style.display = "none";
            }
        });
    } catch (e) {
        console.log(e.message)
    }
}