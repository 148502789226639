import { ComunicadosApi } from "../Api/ComunicadosApi";
import { ComunicadoHomeView } from "../ui/views/ComunicadoHomeView";

export class ComunicadoHomeController {
    constructor() {
        this._comunicadoHomeView = new ComunicadoHomeView("#comunicados");
        this._comunicadosApi = new ComunicadosApi();
        
        this.init();
    }

    async init() {
        const comunicados = await this._comunicadosApi.getAllComunicados();
        
        this._comunicadoHomeView.update(comunicados.slice(0, 2));
    }
}