import { View } from "./View";

export class ConteudoView extends View {
    template(model) {
        return model.map(item => {
            return `
                <div class="atividades">
                    <div class="atividades__header">
                        <div>
                            <div class="atividades__title">
                                <b>
                                    Classe
                                </b>
                            </div>
                            <div class="atividades__subtitle">${item.descricaoAno}</div>
                        </div>
                        <button class="atividades__icon">
                            <span class="material-symbols-outlined">
                                chevron_right
                            </span>
                        </button>
                    </div>

                    <div class="atividades__options" style="overflow-y: auto">
                        ${item.turmas.map(t => {
                            return `
                                <div class="atividades__classes">
                                    <button class="atividades__button">Turma ${t.nomeTurma}</button>
                                    <div class="atividades__group">
                                    ${t.materias.map(m => {
                                        return `
                                            <div class="atividades__links">
                                                <a href="./Arquivos/conteudodeaula/${m.nomeArquivo}" target="_blank">${m.materias}</a>
                                            </div>
                                        `;
                                    }).join("")}
                                    </div>
                                </div>    
                                `;
                            }).join("")}
                    </div>
                    </div>
            `;
        }).join("");
    }
}
    // <a href="/Arquivos/conteudodeaula/${item.nomeArquivoCompleto}"><button class="atividades__button">Ver conteúdo</button></a>