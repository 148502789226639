import { View } from "./View";

export class AlbumsView extends View {
    template(model) {
        if(model.length == 0) return "Nenhum filtro correspondente";
        return model.map(item => {
            return `
            <div class="multimidia__card">
                <div class="multimidia__image">
                    <img src="/Imagens/galeriafotos/${item.codAlbum}/tb-${item.nomeArquivoCapaAlbum}" alt="${item.titulo}"/>
                </div>
                <div class="multimidia__body">
                    <h2 class="multimidia__title"><a href="/multimidia/album/${item.codAlbum}">${item.titulo}</a></h2>
                    <a href="/api/galerias/${item.codAlbum}/download"><button class="multimidia__button">Baixar álbum</button></a>
                </div>
            </div>
            `;
        }).join("");
    }
}