export const accordionSecretaria = () => {
    let accordionHeader;
    let accordionBody;

    try {
        accordionHeader = document.querySelectorAll(".secretaria-card__header");

        accordionHeader.forEach(element => {
            element.addEventListener("click", () => {
                accordionBody = element.nextElementSibling;
                if(accordionBody.style.maxHeight) {
                    accordionBody.style.maxHeight = null;
                } else {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                }
            });
        });
    } catch (error) {
        console.log(error.message)
    }
}

export const accordionAtividades = () => {
    let accordionButton;
    let accordionBody;
    
    try {
        accordionButton = document.querySelectorAll(".atividades__icon");

        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                accordionBody = button.parentNode.nextElementSibling;
                if(accordionBody.style.maxHeight) {
                    accordionBody.style.maxHeight = null;
                    button.innerHTML = `
                        <span class="material-symbols-outlined">
                        chevron_right
                        </span>
                    `;
                    button.style.opacity = "1";
                } else {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                    button.innerHTML = `
                        <span class="material-symbols-outlined">
                        expand_more
                        </span>
                    `;
                    button.style.opacity = "0.5";
                }
            });
        });
    } catch (error) {
        console.log(error.message)
    }
}

export const accordionComunicados = () => {
    let accordionButton;
    let accordionBody;

    try {
        accordionButton = document.querySelectorAll(".comunicado__button");
        accordionBody = document.querySelectorAll(".comunicado__body");
        accordionButton.forEach(button => {
            button.addEventListener("click", () =>  {
                accordionBody = button.parentNode.nextElementSibling;

                if(accordionBody.style.maxHeight) {
                    accordionBody.style.maxHeight = null;
                    button.innerHTML = `
                        <span class="material-symbols-outlined">
                        chevron_right
                        </span>
                    `;
                } else {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
                    button.innerHTML = `
                        <span class="material-symbols-outlined">
                        expand_more
                        </span>
                    `;
                }
            })
        })
    } catch (error) {
        console.log(error.message);
    }
}