import { HttpService } from "../util/HttpService";

export class ComunicadosApi {
    constructor() {
        this._URL_BASE = `https://${window.location.host}/Api`;
        this._httpService = new HttpService();
    }

    getAllComunicados() {
        return this._httpService.get(`${this._URL_BASE}/comunicados`);
    }
}