import { View } from "./View";

export class NoticiasView extends View {
    template(model) {
        return model.map(item => {
            return `
                <div class="news-box">
                <img src="https://images.unsplash.com/photo-1670272498456-a9f02e3cead9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60" alt="" class="news-box__image">
                    <div class="news-box__body">
                        <div class="news-box__tags">
                            ${item.tags.map(tag => {
                                return `<span class="news-box__tag">${tag.tag}</span>`;
                            }).join("")}
                        </div>
                        <h4 class="news-box__title">${item.titulo}</h4>
                        <p class="news-box__subtitle">${String(item.descricao).slice(0, 110)}...</p>

                        <a href="/noticia?id=${item.codNoticia}" class="news-box__link">Continue lendo</a>
                    </div>
                </div>
            `;
        }).join("");
    }
}